import * as Sentry from "@sentry/remix";
/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import posthog from "posthog-js";

import { startTransition, StrictMode, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";
// import { AnalyticsBrowser } from "@segment/analytics-next";

// https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/tree-shaking/
// docs.sentry.io/platforms/javascript/guides/remix/configuration/tree-shaking/
https: Sentry.init({
  dsn: "https://a068ef29c76b8184f7ffc0ae30d15dbb@o230256.ingest.us.sentry.io/4507157988114432",
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.2,
  replaysOnErrorSampleRate: 1.0,
  profilesSampleRate: 1.0,

  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
    }),
    // new Sentry.BrowserProfilingIntegration(),
  ],
});

function PosthogInit() {
  useEffect(() => {
    posthog.init("phc_sXI4IL1icklZlYs0wpVUlKIoxlEM3Ca7o3WakHOBTXR", {
      api_host: "https://us.i.posthog.com",
    });
  }, []);

  return null;
}

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
      <PosthogInit />
    </StrictMode>,
  );
});
